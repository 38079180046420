@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('react-toastify/dist/ReactToastify.min.css');

*,
*::before,
*::after {
  @apply box-border antialiased;

  & {
    -moz-font-feature-settings: 'ss02';
    -webkit-font-feature-settings: 'ss02';
    font-feature-settings: 'ss02';
  }
}

html,
body {
  @apply h-full w-full font-sans;
}

#root {
  @apply flex  w-full flex-col bg-pgiff bg-cover;
  background-image: url(../assets/images/bg.jpeg) !important;
  background-size: cover;
  min-height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
